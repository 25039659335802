import { styled } from '@mui/material/styles';
import { Paper, TableCell, TableRow } from '@mui/material';

export const StyledPaper = styled(Paper)({
  // Any common paper styles
});

export const StyledTableCell = styled(TableCell)(({ theme }) => ({
  fontWeight: "bold",
  textTransform: "uppercase",
}));

export const StyledTableRow = styled(TableRow)(({ theme, selectable }) => ({
  cursor: selectable ? 'pointer' : 'default',
  '&:hover': {
    backgroundColor: selectable ? theme.palette.action.hover : 'inherit',
  }
}));

export const AvatarContainer = styled('div')({
  display: 'flex',
  alignItems: 'center',
});

export const NameContainer = styled('div')({
  marginLeft: '16px',
});

export const tableStyles = {
  checkbox: {
    padding: '0 12px',
  },
  paginationContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
  }
}; 