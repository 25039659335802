import React, { useEffect, useRef } from "react";
import EmailEditor from "react-email-editor";
import { Box, Typography } from "@mui/material";

const Campaigns = ({ exportHtml, onEditorLoad }) => {
  const editorRef = useRef(null);

  useEffect(() => {
    const editorElement = document.getElementById("editor");

    const initializeEditor = () => {
      if (
        editorElement &&
        !editorElement.hasAttribute("data-unlayer-initialized")
      ) {
        window.unlayer.init({
          id: "editor",
          projectId: 256104,
          displayMode: "email",
        });
        editorElement.setAttribute("data-unlayer-initialized", "true");

        if (onEditorLoad) {
          setTimeout(onEditorLoad, 1500);
        }
      }
    };

    if (!window.unlayer) {
      if (
        !document.querySelector(
          'script[src="https://editor.unlayer.com/embed.js"]'
        )
      ) {
        const script = document.createElement("script");
        script.src = "https://editor.unlayer.com/embed.js";
        script.async = true;
        script.onload = initializeEditor;
        document.body.appendChild(script);
      }
    } else {
      initializeEditor();
    }

    return () => {
      if (window.unlayer) {
        window.unlayer.destroy();
        if (editorElement) {
          editorElement.removeAttribute("data-unlayer-initialized");
        }
      }
    };
  }, [onEditorLoad]);

  const handleExportHtml = async () => {
    if (!editorRef.current?.editor) {
      console.error("Editor not initialized");
      return null;
    }

    return new Promise((resolve, reject) => {
      try {
        editorRef.current.editor.exportHtml((data) => {
          const { html } = data;
          if (!html) {
            reject(new Error("No HTML content generated"));
            return;
          }
          resolve(html);
        });
      } catch (error) {
        reject(error);
      }
    });
  };

  useEffect(() => {
    if (exportHtml) {
      exportHtml.current = handleExportHtml;
    }
  }, [exportHtml]);

  return (
    <Box>
      <Typography variant="h5" sx={{ padding: "10px" }}>
        Email manager
      </Typography>
      <div style={{ height: "600px" }}>
        <EmailEditor
          ref={editorRef}
          onReady={onEditorLoad}
          options={{
            id: "editor",
            projectId: 256104,
            displayMode: "email",
            appearance: { theme: "modern_light" },
          }}
        />
      </div>
    </Box>
  );
};

export default Campaigns;
