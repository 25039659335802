import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import {
  Box,
  useMediaQuery,
  useTheme,
  Button,
  Menu,
  MenuItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import { API, Auth, graphqlOperation, Storage } from "aws-amplify";
import {
  deleteAgent,
  getPlaygroundConversation,
  putAgent,
} from "../../graphql/queries";
import useStore from "../../hooks/useStore";
import DataFetcher from "../../utils/DataFetcher";
import ChatBox from "../../components/ChatBox/ChatBox";
import UseNotification from "../../utils/UseNotification";
import AssistantDetails from "../Playground/AssistantDetails";
import ChatWindow from "../../components/ChatWindow/ChatWindow";
import { onChatAssistantResponse } from "../../graphql/subscriptions";
import ConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";
import NotificationDialog from "../../components/NotificationDialog/NotificationDialog";
import KnowlegdeBaseList from "../../components/KnowlegdeBaseList/KnowlegdeBaseList";
import { Email, Sms } from "@mui/icons-material";
import CampaignSlideOver from "./components/CampaignSlideOver";
import { playgroundStyles } from "./Playground.styles";

const ENV = _.isUndefined(process.env.REACT_APP_USER_BRANCH)
  ? "dev"
  : process.env.REACT_APP_USER_BRANCH;
const REGION = _.isUndefined(process.env.REACT_APP_REGION)
  ? "us-east-1"
  : process.env.REACT_APP_REGION;
const APP_LEVEL = _.isUndefined(process.env.REACT_APP_LEVEL)
  ? "internship"
  : process.env.REACT_APP_LEVEL;

const Playground = () => {
  const theme = useTheme();
  const location = useLocation();
  const { authStore } = useStore();
  const dataFetcher = DataFetcher();
  const [files, setFiles] = useState([]);
  const [userId, setUserId] = useState("");
  const [newMessage, setNewMessage] = useState("");
  const [dataMessages, setDataMessages] = useState([]);
  const [agentDeleted, setAgentDeleted] = useState("");
  const [currentAgent, setCurrentAgent] = useState({});
  const [isGroqAgent, setIsGroqAgent] = useState(false);
  const [showSpinner, setShowSpinner] = useState(false);
  const [updateKey, setUpdateKey] = useState(Date.now());
  const [selectedAgent, setSelectedAgent] = useState({});
  const [currentThread, setCurrentThread] = useState("");
  const [uploadedStatus, setUploadedStatus] = useState("");
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const [openDocumentList, setOpenDocumentList] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const [slideOverOpen, setSlideOverOpen] = useState(false);
  const [campaignType, setCampaignType] = useState(null);

  const selectedPrompt = location.state?.selectedPrompt || null;
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const {
    openNotification,
    notificationContent,
    handleOpenNotification,
    handleCloseNotification,
  } = UseNotification();

  const useConfirmationDialog = () => {
    const [openConfirmation, setOpenConfirmation] = useState(false);

    const handleOpenConfirmation = () => {
      setOpenConfirmation(true);
    };

    const handleCloseConfirmation = () => {
      setOpenConfirmation(false);
    };

    return {
      openConfirmation,
      handleOpenConfirmation,
      handleCloseConfirmation,
    };
  };

  const { openConfirmation, handleOpenConfirmation, handleCloseConfirmation } =
    useConfirmationDialog();

  const [messages, setMessages] = useState(() => {
    const savedMessages = localStorage.getItem("CustomAgentMessages");
    return savedMessages ? JSON.parse(savedMessages) : [];
  });

  useEffect(() => {
    const amplifyConfig = Auth.configure();

    if (amplifyConfig && Object.keys(amplifyConfig).length > 0) {
      const identityPoolId = amplifyConfig.aws_cognito_identity_pool_id;

      Storage.configure({
        region: REGION,
        bucket: `${ENV.toLowerCase()}-cai3p0-integrations`,
        identityPoolId: identityPoolId,
        level: APP_LEVEL,
      });
    }
  }, []);

  useEffect(() => {
    if (!authStore.userId) return;

    const initialize = async () => {
      const applicantId = authStore.userId;
      const getFiles = await dataFetcher.fetchFiles(applicantId);
      setUserId(applicantId);
      setFiles(getFiles);
    };

    initialize();
  }, [authStore.userId]);

  useEffect(() => {
    localStorage.setItem("CustomAgentMessages", JSON.stringify(messages));
  }, [messages]);

  const handlePutAgent = async (data) => {
    const { instructions, name, selectedAgentId } = data;
    const ids = files
      .filter((file) => file.file_name !== "case_study_placeholder")
      .map((file) => file.file_name);

    const params = {
      customer_id: "cai3p0",
      id: selectedAgentId || "",
      applicant_id: userId,
      prompt: instructions,
      provider: "openai",
      name: name,
      knowledge_base: ids,
    };

    try {
      const response = await API.graphql({
        query: putAgent,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      setIsGroqAgent(false);
      const typeMessage =
        Object.keys(selectedAgent).length === 0 ? "created" : "updated";

      handleOpenNotification(
        "Success",
        `Agent successfully ${typeMessage}.`,
        "success"
      );

      console.log("response: ", response);
    } catch (error) {
      handleOpenNotification(
        "Error",
        "An error occurred in the process, verify and try again.",
        "error"
      );
      console.error("Error saving/editing agent to database:", error);
    }
  };

  const handleDeleteAgent = async () => {
    handleCloseConfirmation();
    const params = {
      customer_id: "cai3p0",
      id: selectedAgent.id,
    };

    try {
      const response = await API.graphql({
        query: deleteAgent,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      handleOpenNotification(
        "Success",
        "Agent successfully deleted.",
        "success"
      );
      setSelectedAgent({});
      setAgentDeleted("Yes");
      console.log("response: ", response);
    } catch (error) {
      handleOpenNotification(
        "Error",
        "An error occurred while deleting the agent.",
        "error"
      );
      console.error("Error deleting agent from the database:", error);
      setAgentDeleted("No");
    }
  };

  const handleSelectAgent = async (agent) => {
    setAgentDeleted("");
    setIsGroqAgent(false);
    setSelectedAgent(agent);
  };

  const uploadFile = useCallback(
    async (event) => {
      setIsUploadingFile(true);
      const uploadedFiles = await handleFileUpload(event, userId);
      await dataFetcher.saveFilesToDatabase(uploadedFiles, userId);
      setUpdateKey(Date.now());
      setIsUploadingFile(false);
    },
    [dataFetcher, userId]
  );

  const handleFileUpload = async (files, applicantId) => {
    try {
      const uploadedFiles = await Promise.all(
        Array.from(files).map(async (file) => {
          const fileId = file.name.split("-")[0];
          const filePath = `internship/${applicantId}/${file.name}`;
          const result = await Storage.put(filePath, file, {
            contentType: file.type,
          });
          console.log(`File uploaded: ${JSON.stringify(result)}`);
          return {
            file_name: file.name,
            file_description: "file description",
            s3_path: `public/${result.key}`,
            file_id: fileId,
          };
        })
      );
      if (uploadedFiles.length) {
        setUploadedStatus("success");
      }
      return uploadedFiles;
    } catch (error) {
      setUploadedStatus("error");
      throw new Error("Error uploading files:", error);
    }
  };

  const handleSendMessage = async () => {
    if (newMessage.trim() === "") return;

    const newSentMessage = { message: newMessage, type: "sent" };
    const newLoadingMessage = {
      message: "",
      type: "received",
      isLoading: true,
    };

    setCurrentAgent(
      Object.keys(selectedAgent).length === 0 ? selectedAgent : currentAgent
    );

    setMessages((prevMessages) => [...prevMessages, newSentMessage, newLoadingMessage]);
    setShowSpinner(true);

    try {
      const params = {
        customer_id: "cai3p0",
        agent_id: selectedAgent.id,
        message: newMessage,
        thread: Object.keys(selectedAgent).length === 0 ? currentThread : "",
        user_id: userId,
      };

      const resultConversationOpenAi = await API.graphql({
        query: getPlaygroundConversation,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      if (!resultConversationOpenAi.errors?.length) {
        const apiResponse = resultConversationOpenAi.data.getPlaygroundConversation;
        
        // Handle initial response
        if (apiResponse.status === "assistant chat response completed") {
          const responseBody = JSON.parse(apiResponse.body);
          setMessages((prevMessages) => {
            const filteredMessages = prevMessages.filter((msg) => !msg.isLoading);
            return [...filteredMessages, { message: responseBody.answer, type: "received" }];
          });
          setShowSpinner(false);
          setNewMessage("");
          setCurrentThread(`"${responseBody.thread_id}"`);
          
          // Add feedback data
          const feedbackData = {
            user_id: userId,
            vote: "DOWN",
            agent_id: responseBody.agent,
            message: responseBody.answer,
            message_id: responseBody.campaign_id,
            campaign: "",
            comment: "Comment",
          };
          setDataMessages((prevMessages) => [...prevMessages, feedbackData]);
          return; // Exit if we already have the complete response
        }

        // Set up subscription for streaming responses
        let tmpMsg = "";
        let isStreaming = false;

        const assistantResponseSub = API.graphql({
          ...graphqlOperation(onChatAssistantResponse, {
            id: apiResponse.id,
          }),
          authMode: "AMAZON_COGNITO_USER_POOLS",
        }).subscribe({
          next: ({ value }) => {
            const subApiResponse = value.data.onChatAssistantResponse;
            const body = JSON.parse(subApiResponse.body);
            
            if (body.error) {
              setMessages((prevMessages) => 
                prevMessages.map((msg) =>
                  msg.isLoading ? { ...msg, message: body.error, isLoading: false } : msg
                )
              );
              setShowSpinner(false);
              return;
            }

            const openAiAnswer = body.answer;
            setShowSpinner(false);

            if (subApiResponse.status === "done") {
              if (!isStreaming) {
                setMessages((prevMessages) => {
                  const filteredMessages = prevMessages.filter((msg) => !msg.isLoading);
                  return [...filteredMessages, { message: openAiAnswer, type: "received" }];
                });
              }
              assistantResponseSub.unsubscribe();
              setNewMessage("");
              setCurrentThread(`"${body.thread_id}"`);
              
              const feedbackData = {
                user_id: userId,
                vote: "DOWN",
                agent_id: body.agent,
                message: openAiAnswer,
                message_id: body.message_id,
                campaign: "",
                comment: "Comment",
              };
              setDataMessages((prevMessages) => [...prevMessages, feedbackData]);
            } else {
              isStreaming = true;
              tmpMsg += openAiAnswer;
              setMessages((prevMessages) =>
                prevMessages.map((msg) =>
                  msg.isLoading ? { ...msg, message: tmpMsg, isLoading: false } : msg
                )
              );
            }
          },
          error: (error) => {
            console.error("Subscription error:", error);
            setShowSpinner(false);
            setMessages((prevMessages) =>
              prevMessages.map((msg) =>
                msg.isLoading
                  ? {
                      ...msg,
                      message: "Error receiving message. Please try again.",
                      isLoading: false,
                    }
                  : msg
              )
            );
          },
        });
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setShowSpinner(false);
      setMessages((prevMessages) =>
        prevMessages.map((msg) =>
          msg.isLoading
            ? {
                ...msg,
                message: "Error sending message. Please try again.",
                isLoading: false,
              }
            : msg
        )
      );
    }
  };

  const handleClearMessages = () => {
    setMessages([]);
    localStorage.removeItem("AdminChatMessages");
  };

  const handleConfirmationDialog = () => {
    handleOpenConfirmation();
  };

  const handleRejectConfirmation = () => {
    setAgentDeleted("No");
    handleCloseConfirmation();
  };

  const toggleFileList = useCallback(() => {
    setOpenDocumentList((prev) => !prev);
  }, []);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOptionSelect = (option) => {
    setCampaignType(option);
    setSlideOverOpen(true);
    handleClose();
  };

  const handleCloseSlideOver = () => {
    setSlideOverOpen(false);
    setCampaignType(null);
  };

  const hasSelectedAgent = Object.keys(selectedAgent).length > 0;
  const isEmailCampaign = campaignType === "email";

  return (
    <Box
      sx={{
        ...playgroundStyles.container,
        flexDirection: isMobileOrTablet ? "column" : "row",
      }}
    >
      {openDocumentList && (
        <KnowlegdeBaseList
          open={openDocumentList}
          onClose={toggleFileList}
          files={files}
        />
      )}
      <AssistantDetails
        selectedPrompt={selectedPrompt}
        applicantId={userId}
        agentDeleted={agentDeleted}
        onUploadFile={uploadFile}
        onCreateOrUpdate={handlePutAgent}
        onDelete={handleConfirmationDialog}
        onSelectAgent={handleSelectAgent}
        toggleFileList={toggleFileList}
        sx={playgroundStyles.assistantDetails}
      />
      <Box
        sx={{
          ...playgroundStyles.mainContent,
          opacity: hasSelectedAgent ? 1 : 0.5,
          pointerEvents: hasSelectedAgent ? "auto" : "none",
          filter: hasSelectedAgent ? "none" : "grayscale(100%)",
          width: slideOverOpen
            ? `calc(100% - ${isEmailCampaign ? "60%" : "30%"})`
            : "100%",
          marginRight: slideOverOpen ? (isEmailCampaign ? "51.8%" : "31%") : 0,
        }}
      >
        <ChatWindow
          messages={messages}
          isDisabled={!hasSelectedAgent}
          dataMessages={dataMessages}
        />
        <Box sx={playgroundStyles.buttonContainer}>
          <Button
            id="share-button"
            aria-controls={open ? "share-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            onClick={handleClick}
            variant="contained"
            size="small"
            sx={playgroundStyles.campaignButton}
          >
            Start Campaign
          </Button>
          <Menu
            id="share-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              "aria-labelledby": "share-button",
            }}
            sx={playgroundStyles.shareMenu}
          >
            <MenuItem onClick={() => handleOptionSelect("email")}>
              <ListItemIcon>
                <Email fontSize="small" />
              </ListItemIcon>
              <ListItemText>Email</ListItemText>
            </MenuItem>
            <MenuItem onClick={() => handleOptionSelect("sms")}>
              <ListItemIcon>
                <Sms fontSize="small" />
              </ListItemIcon>
              <ListItemText>SMS</ListItemText>
            </MenuItem>
          </Menu>
        </Box>
        <ChatBox
          files={files}
          isAdmin={true}
          isGroqAgent={isGroqAgent}
          showSpinner={showSpinner}
          currentMessage={newMessage}
          uploadedStatus={uploadedStatus}
          isUploadingFile={isUploadingFile}
          onUploadFile={uploadFile}
          onUpdateMessages={(message) => setNewMessage(message)}
          onSendMessage={handleSendMessage}
          clearMessages={handleClearMessages}
          isDisabled={!hasSelectedAgent}
        />
        {!hasSelectedAgent && (
          <Box sx={playgroundStyles.noAgentMessage}>
            No agent has been selected or created
          </Box>
        )}
      </Box>
      <CampaignSlideOver
        open={slideOverOpen}
        type={campaignType}
        onClose={handleCloseSlideOver}
      />
      <NotificationDialog
        open={openNotification}
        onClose={handleCloseNotification}
        title={notificationContent.title}
        message={notificationContent.message}
        type={notificationContent.type}
        sx={playgroundStyles.notificationDialog}
      />
      <ConfirmationDialog
        open={openConfirmation}
        onClose={handleCloseConfirmation}
        onConfirm={handleDeleteAgent}
        onReject={handleRejectConfirmation}
        message="Are you sure you want to delete this agent? This action cannot be undone."
        sx={playgroundStyles.confirmationDialog}
      />
    </Box>
  );
};

export default Playground;
