import React, { useEffect, useState } from "react";
import {
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TablePagination,
  Typography,
} from "@mui/material";
import {
  StyledPaper,
  StyledTableCell,
  StyledTableRow,
  AvatarContainer,
  NameContainer,
  tableStyles,
} from "./DataTable.styles";

const DataTable = ({
  type,
  data,
  columns,
  onSelect,
  selectedRows = [],
  selectable = false,
  showCheckboxes = false,
  multiSelect = false,
}) => {
  const [page, setPage] = useState(0);
  const [sortedData, setSortedData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    const firstColumn = columns.find((col) => col.id !== "checkbox");

    if (firstColumn) {
      const sorted = [...data].sort((a, b) => {
        const valA = (a[firstColumn.id] || "").toString().toLowerCase();
        const valB = (b[firstColumn.id] || "").toString().toLowerCase();
        return valA.localeCompare(valB);
      });
      setSortedData(sorted);
    }
  }, [data, columns]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (row) => {
    return selectedRows.some((selectedRow) => selectedRow.id === row.id);
  };

  const handleSelectAllClick = (event) => {
    if (!onSelect) return;

    if (event.target.checked) {
      onSelect(data); // Send all data rows
    } else {
      onSelect([]); // Send empty array to clear selection
    }
  };

  const handleRowClick = (event, row) => {
    if (!selectable || !onSelect) return;

    // Prevent row selection when clicking checkbox
    if (event.target.type === "checkbox") {
      event.stopPropagation();
      return;
    }

    onSelect(row);
  };

  const handleCheckboxClick = (event, row) => {
    event.stopPropagation();
    if (!onSelect) return;
    onSelect(row);
  };

  const paginatedData = sortedData.slice(
    page * rowsPerPage,
    page * rowsPerPage + rowsPerPage
  );

  return (
    <StyledPaper>
      <TableContainer>
        <Table>
          <TableHead>
            <StyledTableRow>
              {showCheckboxes && (
                <StyledTableCell sx={tableStyles.checkbox}>
                  <Checkbox
                    indeterminate={
                      selectedRows.length > 0 &&
                      selectedRows.length < data.length
                    }
                    checked={
                      data.length > 0 && selectedRows.length === data.length
                    }
                    onChange={handleSelectAllClick}
                  />
                </StyledTableCell>
              )}
              {columns.map((column) => (
                <StyledTableCell key={column.id}>
                  {column.label}
                </StyledTableCell>
              ))}
            </StyledTableRow>
          </TableHead>
          <TableBody>
            {paginatedData.map((row) => {
              const isItemSelected = isSelected(row);
              return (
                <StyledTableRow
                  hover
                  key={row.id}
                  selected={isItemSelected}
                  onClick={(event) => handleRowClick(event, row)}
                  selectable={selectable.toString()}
                >
                  {showCheckboxes && (
                    <StyledTableCell sx={tableStyles.checkbox}>
                      <Checkbox
                        checked={isItemSelected}
                        onClick={(e) => handleCheckboxClick(e, row)}
                      />
                    </StyledTableCell>
                  )}
                  {columns.map((column) => (
                    <StyledTableCell key={column.id}>
                      {column.id === "name" ? (
                        <AvatarContainer>
                          <Avatar>{row?.name[0]}</Avatar>
                          <NameContainer>
                            <Typography variant="body1">{row.name}</Typography>
                          </NameContainer>
                        </AvatarContainer>
                      ) : (
                        row[column.id]
                      )}
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        sx={tableStyles.paginationContainer}
        page={page}
        component="div"
        count={data.length}
        rowsPerPage={rowsPerPage}
        onPageChange={handleChangePage}
        rowsPerPageOptions={[10, 25, 50]}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </StyledPaper>
  );
};

export default DataTable;
