import React, { useMemo } from "react";
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { ArrowRight } from "@mui/icons-material";
import Scrollbars from "react-custom-scrollbars-2";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const KnowlegdeBaseList = ({ open, onClose, files }) => {
  const renderedFiles = useMemo(
    () =>
      files.map((file, index) => (
        <ListItem key={index} sx={{ marginBottom: "-14px", marginTop: "-5px" }}>
          <ListItemIcon sx={{ marginLeft: "-17px", marginRight: "-15px" }}>
            <ArrowRight />
          </ListItemIcon>
          <ListItemText
            primary={
              <Typography
                variant="body2"
                sx={{
                  display: "block",
                  overflow: "hidden",
                  fontWeight: "bold",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {file.file_name}
              </Typography>
            }
            secondary={
              <Typography
                variant="caption"
                sx={{
                  display: "block",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {`Description: ${file.file_description}`}
              </Typography>
            }
          />
        </ListItem>
      )),
    [files]
  );

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
    >
      <Dialog fullWidth maxWidth="sm" open={open} onClose={onClose}>
        <DialogTitle sx={{ m: 0, p: 2 }}>
          <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
            Your Assistant knowledge base
          </Typography>
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              top: 8,
              right: 8,
              height: "5%",
              position: "absolute",
            }}
          >
            <FontAwesomeIcon icon={faXmark} style={{ fontSize: "20px" }} />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers sx={{ height: "500px" }}>
          <Scrollbars style={{ width: "100%", height: "100%" }}>
            <List>{renderedFiles}</List>
          </Scrollbars>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default KnowlegdeBaseList;
