import React, { useState, useEffect, useRef } from "react";
import {
  Box,
  IconButton,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  Alert,
  Snackbar,
  TextField,
} from "@mui/material";
import { API } from "aws-amplify";
import { GridLoader } from "react-spinners";
import { Close, ArrowBack } from "@mui/icons-material";
import Campaigns from "../../Campaigns";
import { sendEmail } from "../../../graphql/queries";
import ContactsSelectionTable from "./ContactsSelectionTable";
import { slideOverStyles } from "./styles/CampaignSlideOver.styles";
import ContactImport from '../../../components/ContactImport/ContactImport';

const steps = ["Select Contacts", "Create Campaign"];

const CampaignSlideOver = ({ open, type, onClose }) => {
  const emailEditorRef = useRef();
  const [error, setError] = useState(null);
  const [domain, setDomain] = useState("");
  const [activeStep, setActiveStep] = useState(0);
  const [senderName, setSenderName] = useState("");
  const [isSending, setIsSending] = useState(false);
  const [senderEmail, setSenderEmail] = useState("");
  const [emailSubject, setEmailSubject] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [isEditorLoading, setIsEditorLoading] = useState(true);
  const [selectedContacts, setSelectedContacts] = useState([]);

  const isEmail = type === "email";

  useEffect(() => {
    if (!open) {
      resetState();
    }
  }, [open]);

  const resetState = () => {
    setSelectedContacts([]);
    setActiveStep(0);
    setIsEditorLoading(true);
    setError(null);
    setSuccessMessage("");
    setEmailSubject("");
  };

  const handleNext = () => {
    console.log("Moving to next step with contacts:", selectedContacts);
    setIsEditorLoading(true);
    setActiveStep(1);
  };

  const handleBack = () => {
    setActiveStep(0);
  };

  const handleEditorLoad = () => {
    setIsEditorLoading(false);
  };

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleContactsImport = (contacts) => {
    console.log('Imported contacts before processing:', contacts);
    
    // Ensure contacts have the required structure
    const processedContacts = contacts.map(contact => ({
      id: contact.id || `imported-${Math.random().toString(36).substr(2, 9)}`,
      email: contact.email?.toLowerCase().trim(),
      name: contact.name || `${contact.firstname || ''} ${contact.lastname || ''}`.trim(),
      phone: contact.phone || '',
      company: contact.company || '',
      position: contact.position || '',
      status: contact.status || 'active'
    }));

    console.log('Processed imported contacts:', processedContacts);
    
    // Update selected contacts and move to next step
    setSelectedContacts(prev => {
      const newContacts = [...prev, ...processedContacts];
      console.log('Updated selected contacts:', newContacts);
      return newContacts;
    });
    
    handleNext();
  };

  const handleSendCampaign = async () => {
    try {
      setError(null);
      setIsSending(true);

      if (!emailSubject.trim()) {
        throw new Error("Please enter an email subject");
      }

      // Get email content
      let emailHtml;
      try {
        emailHtml = await emailEditorRef.current();
        if (!emailHtml || emailHtml.trim().length === 0) {
          throw new Error("Email content cannot be empty");
        }
      } catch (error) {
        console.error("Failed to get email-unlayer content:", error);
        throw new Error("Failed to get email content. Please ensure the editor is loaded properly.");
      }

      // Validate contacts
      if (!selectedContacts || selectedContacts.length === 0) {
        throw new Error("Please select at least one contact");
      }

      // Extract and validate emails
      const emailAddresses = selectedContacts
        .map(contact => {
          const email = contact?.email?.toLowerCase().trim();
          if (!email || !isValidEmail(email)) {
            console.warn('Invalid email found:', email);
            return null;
          }
          return email;
        })
        .filter(email => email !== null);

      console.log('Valid emails to send:', emailAddresses);

      if (emailAddresses.length === 0) {
        throw new Error("No valid email addresses found in selected contacts");
      }

      // Prepare email data
      const emailData = {
        destination: emailAddresses,
        source: process.env.REACT_APP_SES_SENDER_EMAIL || senderEmail,
        subject: emailSubject.trim(),
        text_body: "Your email content",
        html_body: emailHtml
      };

      console.log("Sending email with data:", {
        destinationCount: emailAddresses.length,
        sampleEmails: emailAddresses.slice(0, 3),
        subject: emailData.subject
      });

      const response = await API.graphql({
        query: sendEmail,
        variables: emailData,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      if (response.errors) {
        throw new Error(response.errors[0]?.message || "Failed to send email");
      }

      setSuccessMessage(
        `Campaign sent successfully to ${emailAddresses.length} recipients`
      );

      setTimeout(() => {
        onClose();
      }, 2000);
    } catch (error) {
      console.error("Campaign send failed:", {
        error: error,
        message: error.message,
      });
      setError(error.message || "Failed to send campaign. Please try again.");
    } finally {
      setIsSending(false);
    }
  };

  return open ? (
    <Box
      sx={{
        ...slideOverStyles.container,
        width: isEmail ? "50%" : "30%",
      }}
    >
      <Box sx={slideOverStyles.header}>
        {activeStep === 1 && (
          <IconButton onClick={handleBack} size="small" sx={{ mr: 1 }}>
            <ArrowBack />
          </IconButton>
        )}
        <Typography sx={slideOverStyles.title}>
          {isEmail ? "Email Blast" : "SMS Campaign"}
        </Typography>
        <IconButton onClick={onClose} size="small">
          <Close />
        </IconButton>
      </Box>

      <Stepper activeStep={activeStep} sx={{ p: 2 }}>
        {steps.map((label) => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>

      <Box sx={slideOverStyles.content}>
        {error && (
          <Snackbar
            open={!!error}
            autoHideDuration={6000}
            onClose={() => setError(null)}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={() => setError(null)}
              severity="error"
              sx={{ width: "100%" }}
            >
              {error}
            </Alert>
          </Snackbar>
        )}

        {successMessage && (
          <Snackbar
            open={!!successMessage}
            autoHideDuration={4000}
            onClose={() => setSuccessMessage("")}
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
          >
            <Alert
              onClose={() => setSuccessMessage("")}
              severity="success"
              sx={{ width: "100%" }}
            >
              {successMessage}
            </Alert>
          </Snackbar>
        )}

        {activeStep === 0 ? (
          <Box sx={{ 
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            overflow: 'hidden' // Prevent content overflow
          }}>
            <Box sx={{ 
              flex: 1,
              overflow: 'auto', // Make contact table scrollable
              minHeight: 0 // Required for flex child to scroll
            }}>
              <ContactsSelectionTable
                onNext={handleNext}
                selectedContacts={selectedContacts}
                setSelectedContacts={setSelectedContacts}
              />
            </Box>
            <Box sx={{ 
              borderTop: '1px solid #eee',
              pt: 2,
              mt: 2,
              backgroundColor: '#fff',
              position: 'relative',
              zIndex: 1,
              maxHeight: '300px', // Limit import section height
              overflow: 'auto' // Make import section scrollable if needed
            }}>
              <ContactImport onImportComplete={handleContactsImport} />
            </Box>
          </Box>
        ) : (
          <Box sx={{ height: "100%", position: "relative" }}>
            {isEmail ? (
              <>
                <Box sx={{ p: 2 }}>
                  <Box sx={{ display: "flex", gap: 2 }}>
                    <TextField
                      fullWidth
                      label="Sender Name"
                      value={senderName}
                      onChange={(e) => setSenderName(e.target.value)}
                      error={!senderName.trim() && isSending}
                      helperText={
                        !senderName.trim() && isSending
                          ? "Sender name is required"
                          : ""
                      }
                      sx={{
                        mb: 2,
                        "& .MuiInputBase-root": { height: "36px" },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": { borderColor: "black" },
                          "&:hover fieldset": { borderColor: "black" },
                          "&.Mui-focused fieldset": { borderColor: "black" },
                        },
                      }}
                      InputLabelProps={{
                        sx: { mt: "-5px", fontSize: "12px" },
                      }}
                    />
                    <TextField
                      fullWidth
                      label="Domain"
                      value={domain}
                      onChange={(e) => setDomain(e.target.value)}
                      error={!domain.trim() && isSending}
                      helperText={
                        !domain.trim() && isSending ? "Domain is required" : ""
                      }
                      sx={{
                        mb: 2,
                        "& .MuiInputBase-root": { height: "36px" },
                        "& .MuiOutlinedInput-root": {
                          "& fieldset": { borderColor: "black" },
                          "&:hover fieldset": { borderColor: "black" },
                          "&.Mui-focused fieldset": { borderColor: "black" },
                        },
                      }}
                      InputLabelProps={{
                        sx: { mt: "-5px", fontSize: "12px" },
                      }}
                    />
                  </Box>
                  <TextField
                    fullWidth
                    label="Sender Email Address"
                    value={senderEmail}
                    onChange={(e) => setSenderEmail(e.target.value)}
                    error={!senderEmail.trim() && isSending}
                    helperText={
                      !senderEmail.trim() && isSending
                        ? "Sender email is required"
                        : ""
                    }
                    sx={{
                      mb: 2,
                      "& .MuiInputBase-root": { height: "36px" },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: "black" },
                        "&:hover fieldset": { borderColor: "black" },
                        "&.Mui-focused fieldset": { borderColor: "black" },
                      },
                    }}
                    InputLabelProps={{
                      sx: { mt: "-5px", fontSize: "12px" },
                    }}
                  />
                  <TextField
                    fullWidth
                    label="Email Subject"
                    value={emailSubject}
                    onChange={(e) => setEmailSubject(e.target.value)}
                    error={!emailSubject.trim() && isSending}
                    helperText={
                      !emailSubject.trim() && isSending
                        ? "Subject is required"
                        : ""
                    }
                    sx={{
                      mb: 2,
                      "& .MuiInputBase-root": { height: "36px" },
                      "& .MuiOutlinedInput-root": {
                        "& fieldset": { borderColor: "black" },
                        "&:hover fieldset": { borderColor: "black" },
                        "&.Mui-focused fieldset": { borderColor: "black" },
                      },
                    }}
                    InputLabelProps={{
                      sx: { mt: "-5px", fontSize: "12px" },
                    }}
                  />
                </Box>
                {isEditorLoading && (
                  <Box sx={slideOverStyles.loaderContainer}>
                    <GridLoader />
                  </Box>
                )}
                <Campaigns
                  selectedContacts={selectedContacts}
                  onEditorLoad={handleEditorLoad}
                  exportHtml={emailEditorRef}
                />
                <Button
                  variant="contained"
                  onClick={handleSendCampaign}
                  disabled={isSending || !emailSubject.trim()}
                  sx={{
                    position: "absolute",
                    bottom: 16,
                    right: 16,
                    backgroundColor: "#015d86",
                    "&:hover": { backgroundColor: "#014a6d" },
                  }}
                >
                  {isSending ? "Sending..." : "Send Campaign"}
                </Button>
              </>
            ) : (
              <Box sx={{ p: 2 }}>
                <Typography>SMS Campaign content</Typography>
              </Box>
            )}
          </Box>
        )}
      </Box>
    </Box>
  ) : null;
};

export default CampaignSlideOver;
