import React, { useState, useEffect, useCallback } from "react";
import { Box, Button, Typography } from "@mui/material";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { useLocation, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PromptTags from "./components/PromptTags";
import PromptNameField from "./components/PromptNameField";
import SaveDeleteButtons from "./components/SaveDeleteButtons";
import PromptDescriptionField from "./components/PromptDescriptionField";
import { DEFAULT_PROMPT_STATE, ERROR_MESSAGES } from "./utils/constants";
import PromptInstructionsField from "./components/PromptInstructionsField";
import ConfirmationDialog from "../../components/ConfirmationDialog/ConfirmationDialog";

const PromptBuilder = ({ selectedPrompt, onSave, onDelete }) => {
  let prompt = null;

  const navigate = useNavigate();
  const location = useLocation();

  if (!selectedPrompt) {
    prompt = location.state?.currentPrompt;
  }

  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [promptState, setPromptState] = useState(DEFAULT_PROMPT_STATE);

  useEffect(() => {
    setPromptState(selectedPrompt || prompt || DEFAULT_PROMPT_STATE);
    setErrors({});
  }, [selectedPrompt, prompt]);

  const validateForm = useCallback(() => {
    const newErrors = {};
    if (!promptState.name.trim())
      newErrors.name = ERROR_MESSAGES.PROMPT_NAME_REQUIRED;
    if (!promptState.instructions.trim())
      newErrors.instructions = ERROR_MESSAGES.INSTRUCTIONS_REQUIRED;
    if (
      promptState.inputVariables.some((v) => v.name.trim() && !v.value.trim())
    ) {
      newErrors.variables = ERROR_MESSAGES.VARIABLES_INCOMPLETE;
    }
    return newErrors;
  }, [promptState]);

  const handleInputChange = useCallback((e, field) => {
    if (field === "name") {
      const formattedValue = e.target.value
        .replace(/\s+/g, "_")
        .replace(/[^a-zA-Z0-9_]/g, "")
        .toLowerCase();
      setPromptState((prev) => ({ ...prev, [field]: formattedValue }));
    } else {
      setPromptState((prev) => ({ ...prev, [field]: e.target.value }));
    }
    setErrors((prev) => ({ ...prev, [field]: "" }));
  }, []);

  const handleAddTag = useCallback(
    (tag) => {
      if (!promptState.tags.includes(tag)) {
        setPromptState((prev) => ({ ...prev, tags: [...prev.tags, tag] }));
      }
    },
    [promptState.tags]
  );

  const handleDeleteTag = useCallback((indexToDelete) => {
    setPromptState((prev) => ({
      ...prev,
      tags: prev.tags.filter((_, index) => index !== indexToDelete),
    }));
  }, []);

  const handleCreateClick = () => {
    setErrors({});
    const formErrors = validateForm();

    if (Object.keys(formErrors).length > 0) {
      setErrors(formErrors);
      setIsLoading(false);
      return;
    }
    setCreateDialogOpen(true);
  };

  const handleCreateDialogClose = () => setCreateDialogOpen(false);
  const handleDeleteClick = () => setDeleteDialogOpen(true);
  const handleDeleteDialogClose = () => setDeleteDialogOpen(false);

  const handleConfirmDelete = async () => {
    try {
      await onDelete(selectedPrompt.name);
      console.log(`Prompt "${selectedPrompt?.name}" deleted.`);
      setDeleteDialogOpen(false);
    } catch (error) {
      console.error("Error deleting prompt:", error);
      setDeleteDialogOpen(false);
    }
  };

  const handleConfirmCreate = useCallback(async () => {
    setIsLoading(true);
    setCreateDialogOpen(false);

    try {
      await onSave({
        name: promptState.name.trim(),
        description: promptState.description.trim(),
        template: promptState.instructions.trim(),
        tags: promptState.tags.length === 0 ? [""] : promptState.tags,
      });
      console.log("Prompt saved successfully");
    } catch (error) {
      console.error("Error saving prompt:", error);
    } finally {
      setIsLoading(false);
    }
  }, [promptState, onSave]);

  return (
    <Box role="form" aria-label="Prompt Builder Form">
      {promptState && (
        <>
          <Box
            p={2}
            ml={7}
            mr={7}
            gap={2}
            height="100%"
            display="flex"
            minHeight="100%"
            position="relative"
            flexDirection="column"
          >
            <Box
              gap={2}
              display="flex"
              alignItems="center"
              justifyContent={"flex-end"}
            >
              <Button
                disabled={!promptState?.name || !promptState?.instructions}
                variant="contained"
                color="success"
                startIcon={<FontAwesomeIcon icon={faPlay} />}
                sx={{
                  minWidth: "150px",
                  textTransform: "none",
                }}
                onClick={() => {
                  navigate("/playground", { state: { selectedPrompt } });
                }}
              >
                <Typography sx={{ fontSize: "14px", marginTop: "1px" }}>
                  Try it in playground
                </Typography>
              </Button>
            </Box>
            <PromptNameField
              name={promptState?.name}
              commit={promptState?.currentCommit}
              error={errors.name}
              readOnly={selectedPrompt?.name || prompt?.name ? true : null}
              onChange={handleInputChange}
            />
            <PromptDescriptionField
              description={promptState?.description}
              onChange={handleInputChange}
            />
            <PromptInstructionsField
              instructions={promptState?.instructions}
              error={errors.instructions}
              onChange={handleInputChange}
            />
            <PromptTags
              tags={promptState?.tags}
              onAddTag={handleAddTag}
              onDeleteTag={handleDeleteTag}
            />
            <SaveDeleteButtons
              isLoading={isLoading}
              prompt={selectedPrompt?.name || prompt?.name}
              onCreate={handleCreateClick}
              onDelete={handleDeleteClick}
            />
            <ConfirmationDialog
              open={createDialogOpen}
              onClose={handleCreateDialogClose}
              onConfirm={handleConfirmCreate}
              onReject={handleCreateDialogClose}
              title={
                selectedPrompt?.name ? "Confirm Update" : "Confirm Creation"
              }
              message={`Are you sure you want to ${
                selectedPrompt?.name ? "update" : "create"
              } the prompt "${promptState?.name}"?`}
              secondaryMessage={
                selectedPrompt?.name
                  ? ""
                  : "Prompt names can't change once created"
              }
            />
            <ConfirmationDialog
              open={deleteDialogOpen}
              onClose={handleDeleteDialogClose}
              onConfirm={handleConfirmDelete}
              onReject={handleDeleteDialogClose}
              title={"Confirm Delete"}
              message={`Are you sure you want to delete the prompt "${selectedPrompt?.name}"?`}
            />
          </Box>
        </>
      )}
    </Box>
  );
};

export default PromptBuilder;
