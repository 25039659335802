import React, { useCallback, useEffect, useState } from "react";
import {
  Alert,
  Box,
  Button,
  Drawer,
  Snackbar,
  Tab,
  Tabs,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { API } from "aws-amplify";
import { Tune } from "@mui/icons-material";
import { GridLoader } from "react-spinners";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import FiltersModal from "./FiltersModal";
import UserForm from "../../components/UserForm/UserForm";
import DataTable from "../../components/DataTable/DataTable";
import {
  COLUMN_CUSTOMERS,
  CUSTOMER_DATA_VALUES,
  CUSTOMER_FIELDS,
} from "./utils/constants";
import {
  deleteCustomer,
  getCustomer,
  putCustomer,
} from "../../graphql/queries";

const Customers = () => {
  const theme = useTheme();
  const [customers, setCustomers] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [selectedTab, setSelectedTab] = useState("All");
  const [customersLoaded, setCustomersLoaded] = useState(false);
  const [filterModalOpen, setFilterModalOpen] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [filteredCustomers, setFilteredCustomers] = useState([]);
  const [alert, setAlert] = useState({ message: null, severity: "error" });
  const [filters, setFilters] = useState({
    name: "",
    email: "",
    phone: "",
    date: "",
  });

  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  useEffect(() => {
    if (!customersLoaded) {
      getCustomers();
    }
  }, [customersLoaded]);

  const getCustomers = async () => {
    try {
      const fetchedCustomers = await fetchAccounts();
      if (fetchedCustomers?.length) {
        setCustomers(fetchedCustomers);
        setFilteredCustomers(fetchedCustomers);
        setCustomersLoaded(true);
      }
    } catch (error) {
      handleError("An error occurred while fetching customers.");
    }
  };

  const fetchAccounts = async () => {
    const params = { id: "", status: "" };
    try {
      const response = await API.graphql({
        query: getCustomer,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      console.log("CUSTOMERS: ", JSON.parse(response.data.getCustomer.body));
      return JSON.parse(response.data.getCustomer.body);
    } catch (error) {
      throw new Error("Error fetching customers");
    }
  };

  const putAccount = async (formData) => {
    const params = { ...CUSTOMER_DATA_VALUES, ...formData };
    try {
      const response = await API.graphql({
        query: putCustomer,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      handleAlert(response.data.putCustomer.body, "success");
    } catch (error) {
      handleError("An error occurred while creating/updating customer.");
    } finally {
      setCustomersLoaded(false);
    }
  };

  const deleteAccount = async (customerId) => {
    const params = { id: customerId };
    try {
      const response = await API.graphql({
        query: deleteCustomer,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      console.log("response: ", response);
      handleAlert("Customer has been successfully removed.", "success");
      setCustomersLoaded(false);
    } catch (error) {
      console.log("Error deleting data: ", error);
      throw new Error("Error deleting data: ", error);
    }
  };

  const handleAlert = (message, severity) => {
    setAlert({ message, severity });
  };

  const handleError = (errorMessage) => {
    handleAlert(errorMessage, "error");
  };

  const applyFilters = useCallback(async () => {
    try {
      const fetchedCustomers = await fetchAccounts();
      const filtered = fetchedCustomers.filter((customer) =>
        Object.keys(filters).every(
          (key) =>
            filters[key] === "" ||
            customer[key]?.toLowerCase().includes(filters[key].toLowerCase())
        )
      );
      setFilteredCustomers(filtered);
      setFilterModalOpen(false);
    } catch (error) {
      handleError("An error occurred while applying filters.");
    }
  }, [filters]);

  const filterCustomersByTab = () => {
    switch (selectedTab) {
      case "Accounts":
        return filteredCustomers;
      default:
        return filteredCustomers;
    }
  };

  const clearFilters = () => {
    setFilters({
      name: "",
      email: "",
      phone: "",
      date: "",
    });
    setFilteredCustomers(customers);
  };

  const toggleDrawer = (open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setDrawerOpen(open);
  };

  const openDrawerForEdit = (customer) => {
    setSelectedCustomer(customer);
    setDrawerOpen(true);
  };

  const openDrawerForNew = () => {
    setSelectedCustomer(null);
    setDrawerOpen(true);
  };

  return (
    <Box p={3}>
      <Box
        mb={2}
        display="flex"
        alignItems="center"
        sx={{ marginBottom: "4vh" }}
        justifyContent="space-between"
      >
        <Typography variant="h4" sx={{ fontWeight: "bold" }}>
          Customers
        </Typography>
        <Box
          display="flex"
          sx={{ gap: isMobile ? "10px" : "15px" }}
          flexDirection={isMobile ? "column" : "row"}
          alignItems={isMobile ? "flex-end" : "center"}
        >
          {Object.values(filters).some((filter) => filter !== "") && (
            <Button
              variant="contained"
              sx={{
                color: "#F7E7CE",
                marginRight: !isMobile ?? "15px",
                textTransform: "none",
                backgroundColor: "#FF5349",
                "&:hover": { color: "white", backgroundColor: "#E41F08" },
              }}
              onClick={clearFilters}
              startIcon={
                <FontAwesomeIcon icon={faXmark} style={{ fontSize: "16px" }} />
              }
            >
              Clear Filters
            </Button>
          )}
          <Tooltip title="Filters">
            <Button
              variant="contained"
              sx={{
                color: "grey",
                marginRight: !isMobile ?? "15px",
                textTransform: "none",
                backgroundColor: "#E5E4E2",
                "&:hover": { color: "white", backgroundColor: "#015d86" },
              }}
              onClick={() => setFilterModalOpen(true)}
            >
              <Tune />
            </Button>
          </Tooltip>
          {/* <Button
            variant="contained"
            sx={{
              color: "grey",
              marginRight: !isMobile ?? "15px",
              textTransform: "none",
              backgroundColor: "#E5E4E2",
              "&:hover": { color: "white", backgroundColor: "#015d86" },
            }}
            startIcon={
              <FontAwesomeIcon icon={faDownload} style={{ fontSize: "16px" }} />
            }
          >
            Export
          </Button> */}
          <Button
            variant="contained"
            sx={{
              textTransform: "none",
              backgroundColor: "#015d86",
              "&:hover": { backgroundColor: "#1a749c" },
            }}
            startIcon={
              <FontAwesomeIcon icon={faPlus} style={{ fontSize: "13px" }} />
            }
            onClick={openDrawerForNew}
          >
            <Typography sx={{ fontSize: "13px" }}>New customer</Typography>
          </Button>
        </Box>
      </Box>
      {customersLoaded ? (
        <>
          <Tabs
            value={selectedTab}
            onChange={(e, newValue) => setSelectedTab(newValue)}
            aria-label="customer tabs"
          >
            <Tab
              value="All"
              label={
                <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
                  All
                </Typography>
              }
            />
            <Tab
              value="Accounts"
              label={
                <Typography style={{ fontSize: "14px", fontWeight: "bold" }}>
                  Accounts
                </Typography>
              }
            />
          </Tabs>
          <Typography variant="subtitle1" sx={{ margin: "1vh 0vh 1vh 0vh" }}>
            <strong>{filterCustomersByTab().length}</strong> in total
          </Typography>
          <DataTable
            type={"customer"}
            data={filterCustomersByTab()}
            columns={COLUMN_CUSTOMERS}
            onEdit={openDrawerForEdit}
            onDelete={deleteAccount}
          />
        </>
      ) : (
        <Box
          sx={{
            display: "flex",
            marginTop: "20%",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <GridLoader />
        </Box>
      )}
      <Drawer anchor="right" open={drawerOpen} onClose={toggleDrawer(false)}>
        <UserForm
          fields={CUSTOMER_FIELDS}
          initialData={selectedCustomer || {}}
          title={selectedCustomer ? "Edit Customer" : "New Customer"}
          submitButtonText={selectedCustomer ? "Update" : "Create"}
          onClose={() => setDrawerOpen(false)}
          onSubmit={putAccount}
        />
      </Drawer>
      <FiltersModal
        open={filterModalOpen}
        onClose={() => setFilterModalOpen(false)}
        filters={filters}
        setFilters={setFilters}
        applyFilters={applyFilters}
      />
      <Snackbar
        open={!!alert.message}
        autoHideDuration={6000}
        onClose={() => setAlert({ message: null })}
      >
        <Alert
          onClose={() => setAlert({ message: null })}
          severity={alert.severity}
          sx={{ width: "100%" }}
        >
          {alert.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default Customers;
