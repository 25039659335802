export const slideOverStyles = {
  container: {
    top: 0,
    right: 0,
    bottom: 0,
    zIndex: 1200,
    position: 'fixed',
    background: '#fff',
    borderLeft: '1px solid rgba(0, 0, 0, 0.12)',
    transform: open => open ? 'translateX(0)' : 'translateX(100%)',
    transition: 'all 0.3s ease-in-out',
    boxShadow: '-4px 0 6px -1px rgba(0, 0, 0, 0.1)',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    p: 2,
    display: 'flex',
    alignItems: 'center',
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
    backgroundColor: '#fff',
    zIndex: 1,
  },
  title: {
    fontSize: '16px',
    fontWeight: 600,
    flex: 1,
  },
  content: {
    flexGrow: 1,
    overflow: 'auto',
    height: 'calc(100% - 120px)',
    position: 'relative',
  },
  loaderContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#fff',
    zIndex: 1,
  }
}; 