import React from "react";
import { Box, Button, Typography } from "@mui/material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlus } from "@fortawesome/free-solid-svg-icons";

const AddQuestionButton = ({ onClick }) => (
  <Box sx={{ display: "flex", justifyContent: "center" }}>
    <Button
      variant="contained"
      sx={{
        marginBottom: "30px",
        borderColor: "#015d86",
        backgroundColor: "white",
        "&:hover": { backgroundColor: "#F0F8FF" },
      }}
      onClick={onClick}
      startIcon={
        <FontAwesomeIcon icon={faCirclePlus} style={{ color: "#015d86" }} />
      }
    >
      <Typography
        sx={{ color: "#015d86", fontSize: "14px", textTransform: "none" }}
      >
        Add question
      </Typography>
    </Button>
  </Box>
);

export default AddQuestionButton;
