import React, { useEffect, useRef, useState } from "react";
import { Alert, Box } from "@mui/material";
import { Scrollbars } from "react-custom-scrollbars-2";
import GroupNameField from "./components/GroupNameField";
import QuestionsFields from "./components/QuestionsFields";
import AddQuestionButton from "./components/AddQuestionButton";
import QuestionsBuilderButtons from "./components/QuestionsBuilderButtons";

const QuestionsBuilder = ({
  currentInterview,
  initialGroupName,
  questions,
  onAddQuestionField,
  onBack,
  handleQuestionChange,
  handleNameChange,
  handleDeleteQuestion,
  onSave,
}) => {
  const scrollRef = useRef(null);
  const [alertMessage, setAlertMessage] = useState("");
  const [errorGroupName, setErrorGroupName] = useState("");
  const [errorQuestions, setErrorQuestions] = useState([]);
  const [localQuestions, setLocalQuestions] = useState(questions || []);
  const [localGroupName, setLocalGroupName] = useState(initialGroupName || "");

  useEffect(() => {
    setLocalQuestions(questions);
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({
        block: "end",
        behavior: "smooth",
      });
    }
  }, [questions]);

  const handleSaveInterview = () => {
    setAlertMessage("");
    setErrorGroupName("");

    const emptyQuestions = localQuestions.map((q) => q === "");
    setErrorQuestions(emptyQuestions);

    if (!localGroupName) {
      setErrorGroupName("Required");
      setAlertMessage("The interview must have a name.");
    } else if (localQuestions.length < 3) {
      setErrorGroupName("");
      setAlertMessage(
        "The interview must have at least three questions to be saved."
      );
    } else if (emptyQuestions.includes(true)) {
      setAlertMessage("All questions must be filled in.");
    } else {
      setAlertMessage("");
      setErrorGroupName("");
      setErrorQuestions([]);
      onSave(localQuestions);
    }
  };

  const handleAddNewQuestions = () => {
    setAlertMessage("");
    onAddQuestionField();
  };

  return (
    <Box
      sx={{
        padding: 2,
        height: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <GroupNameField
        groupName={localGroupName}
        error={errorGroupName}
        onChange={(value) => {
          setLocalGroupName(value);
          handleNameChange(value);
        }}
      />
      <Box
        sx={{
          flexGrow: 1,
          width: "100%",
          padding: "20px",
          display: "flex",
          marginBottom: 2,
          alignSelf: "center",
        }}
      >
        <Scrollbars
          style={{ width: "100%", maxHeight: 600, overflowX: "hidden" }}
        >
          {localQuestions.map((question, index) => (
            <QuestionsFields
              key={index}
              index={index}
              question={question}
              onChange={(e) => handleQuestionChange(index, e.target.value)}
              onDelete={() => handleDeleteQuestion(index)}
              disableDelete={questions.length <= 1}
              error={errorQuestions[index]}
            />
          ))}
          <Box ref={scrollRef} />
        </Scrollbars>
      </Box>
      <AddQuestionButton onClick={handleAddNewQuestions} />
      {alertMessage !== "" && (
        <Box sx={{ marginBottom: "10px" }}>
          <Alert
            severity="error"
            variant="outlined"
            onClose={() => setAlertMessage("")}
          >
            {alertMessage}
          </Alert>
        </Box>
      )}
      <QuestionsBuilderButtons
        currentInterview={currentInterview !== ""}
        onSave={handleSaveInterview}
        onBack={onBack}
      />
    </Box>
  );
};

export default QuestionsBuilder;
