import React from "react";
import { Box, Button, Typography } from "@mui/material";

const QuestionsBuilderButtons = ({ currentInterview, onSave, onBack }) => (
  <Box
    sx={{ display: "flex", marginTop: "auto", justifyContent: "space-between" }}
  >
    <Button
      variant="contained"
      color="primary"
      sx={{
        marginLeft: 2,
        backgroundColor: "#015d86",
        "&:hover": { backgroundColor: "#014a6d" },
      }}
      onClick={onSave}
    >
      <Typography sx={{ fontSize: "14px", textTransform: "none" }}>
        {currentInterview ? "Update questions group" : "Create questions group"}
      </Typography>
    </Button>
    <Button
      variant="outlined"
      onClick={onBack}
      sx={{
        marginRight: 2,
        color: "white",
        borderColor: "white",
        backgroundColor: "#D2122E",
        "&:hover": { color: "#D2122E", borderColor: "#D2122E" },
      }}
    >
      <Typography sx={{ fontSize: "14px", textTransform: "none" }}>
        Cancel
      </Typography>
    </Button>
  </Box>
);

export default QuestionsBuilderButtons;
