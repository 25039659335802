import React from "react";
import { TextField, Typography } from "@mui/material";

const GroupNameField = ({ groupName, error, onChange }) => (
  <>
    <Typography variant="h6" fontWeight="bold" sx={{ marginBottom: "5px" }}>
      Name <span style={{ color: "red" }}>*</span>{" "}
    </Typography>
    <TextField
      required
      id="group-name"
      placeholder="Group Name"
      value={groupName}
      error={!!error}
      helperText={error}
      onChange={(e) => onChange(e.target.value)}
      InputProps={{
        sx: { fontSize: "14px" },
      }}
      fullWidth
      variant="outlined"
      aria-required="true"
      aria-invalid={!!error}
      aria-describedby="group-name-error"
      sx={{
        marginTop: "4px",
        marginBottom: "20px",
        backgroundColor: "white",
        "& .MuiInputBase-root": { height: "40px" },
        "& .MuiOutlinedInput-root": {
          "&.Mui-focused fieldset": { borderColor: "black" },
        },
      }}
    />
  </>
);

export default GroupNameField;
