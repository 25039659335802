import React from "react";
import Scrollbars from "react-custom-scrollbars-2";
import { Box, TextField, Typography } from "@mui/material";
import InfoAlert from "../../../components/shared/material-components/InfoAlert";

const InputVariablesFields = ({ variables, onUpdateVariable }) => {
  return (
    <>
      <Typography
        mt={1}
        mb={-1}
        variant="body1"
        fontSize="14px"
        fontWeight="bold"
      >
        Input Variables:
      </Typography>
      <Box
        gap={2}
        height="250%"
        display="flex"
        overflow="auto"
        paddingTop="4px"
        flexDirection="column"
      >
        <Scrollbars style={{ height: "100%" }}>
          {variables.map((variable, index) => (
            <Box
              key={index}
              display="flex"
              gap={1}
              sx={{ marginBottom: "5px" }}
            >
              <TextField
                placeholder="Name"
                value={variable.name}
                onChange={(e) => onUpdateVariable(index, true, e.target.value)}
                variant="outlined"
                readOn
                fullWidth
                sx={{
                  backgroundColor: "#f0f0f0",
                  "& .MuiInputBase-root": {
                    height: "36px",
                    fontSize: "12px",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                    borderColor: "black",
                  },
                }}
                InputProps={{ readOnly: true }}
              />
              <TextField
                placeholder="Value"
                value={variable.value}
                onChange={(e) => onUpdateVariable(index, false, e.target.value)}
                variant="outlined"
                fullWidth
                sx={{
                  "& .MuiInputBase-root": {
                    height: "36px",
                    fontSize: "12px",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused fieldset": {
                    borderColor: "black",
                  },
                }}
              />
            </Box>
          ))}
        </Scrollbars>
      </Box>
      <InfoAlert
        textSize={"12px"}
        message={
          "You must save the changes to see the value of the variables reflected in your prompt. "
        }
      />
    </>
  );
};

export default InputVariablesFields;
