import React, { useState, useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { 
  Box, 
  Typography, 
  Paper, 
  Button, 
  Select, 
  MenuItem, 
  FormControl, 
  InputLabel,
  Grid,
  Alert,
  LinearProgress,
  IconButton
} from '@mui/material';
import { CloudUpload, Check, Refresh } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import { styles } from './ContactImport.styles';

const REQUIRED_FIELDS = ['email'];
const OPTIONAL_FIELDS = [
  'name',
  'lastname',
  'address',
  'phone',
  'company'
];
const ALL_FIELDS = [...REQUIRED_FIELDS, ...OPTIONAL_FIELDS];

const FIELD_LABELS = {
  email: 'Email',
  name: 'First Name',
  lastname: 'Last Name',
  address: 'Address',
  phone: 'Phone Number',
  company: 'Company'
};

const ContactImport = ({ onImportComplete }) => {
  const [fileData, setFileData] = useState(null);
  const [mappedFields, setMappedFields] = useState({});
  const [availableFields, setAvailableFields] = useState([]);
  const [error, setError] = useState(null);
  const [importing, setImporting] = useState(false);
  const [preview, setPreview] = useState([]);
  const [importSuccess, setImportSuccess] = useState(false);
  const [importedCount, setImportedCount] = useState(0);

  const isValidEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const validateMapping = () => {
    if (!mappedFields.email) {
      setError('Email field mapping is required');
      return false;
    }
    return true;
  };

  const onDrop = useCallback(async (acceptedFiles) => {
    setError(null);
    const file = acceptedFiles[0];
    
    try {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const firstSheet = workbook.Sheets[workbook.SheetNames[0]];
        const jsonData = XLSX.utils.sheet_to_json(firstSheet, { header: 1, raw: false });
        
        if (jsonData.length < 2) {
          setError('File must contain at least one header row and one data row');
          return;
        }

        const headers = jsonData[0].map(header => 
          header ? header.toString().trim() : ''
        );

        if (headers.length === 0) {
          setError('No headers found in the file');
          return;
        }

        setAvailableFields(headers);
        setFileData(jsonData);
        setPreview(jsonData.slice(1, 4));
        
        // Auto-map fields based on common header names
        const initialMapping = {};
        headers.forEach((header) => {
          if (!header) return;
          
          const headerLower = header.toLowerCase();
          
          // Map email field
          if (headerLower.includes('email')) {
            initialMapping.email = header;
          }
          // Map name field
          else if (headerLower.includes('first') || headerLower === 'name') {
            initialMapping.name = header;
          }
          // Map lastname field
          else if (headerLower.includes('last') || headerLower.includes('surname')) {
            initialMapping.lastname = header;
          }
          // Map address field
          else if (headerLower.includes('address')) {
            initialMapping.address = header;
          }
          // Map phone field
          else if (headerLower.includes('phone') || headerLower.includes('tel')) {
            initialMapping.phone = header;
          }
          // Map company field
          else if (headerLower.includes('company') || headerLower.includes('organization')) {
            initialMapping.company = header;
          }
        });
        
        setMappedFields(initialMapping);
        
        console.log('File processed:', {
          headers,
          rowCount: jsonData.length,
          mappedFields: initialMapping
        });
      };
      reader.readAsArrayBuffer(file);
    } catch (error) {
      console.error('File processing error:', error);
      setError('Error reading file: ' + error.message);
    }
  }, []);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls'],
      'text/csv': ['.csv']
    },
    multiple: false
  });

  const handleFieldMapping = (systemField, fileField) => {
    setMappedFields(prev => ({
      ...prev,
      [systemField]: fileField
    }));
  };

  const handleImport = async () => {
    if (!validateMapping()) return;
    
    setImporting(true);
    setError(null);

    try {
        const headers = fileData[0];
        const emailIndex = headers.indexOf(mappedFields.email);
        
        if (emailIndex === -1) {
            throw new Error('Email column not found');
        }

        // Extract valid emails and create contact objects
        const contacts = fileData.slice(1)
            .map((row, index) => {
                const email = row[emailIndex]?.toString().trim().toLowerCase();
                if (!email || !isValidEmail(email)) {
                    console.warn(`Invalid email in row ${index + 2}:`, email);
                    return null;
                }

                // Create contact object with required structure
                return {
                    id: `imported-${Math.random().toString(36).substr(2, 9)}`,
                    email: email,
                    name: row[headers.indexOf(mappedFields.name)] || '',
                    phone: row[headers.indexOf(mappedFields.phone)] || '',
                    company: row[headers.indexOf(mappedFields.company)] || '',
                    position: row[headers.indexOf(mappedFields.position)] || '',
                    // Add any other required fields with proper fallbacks
                };
            })
            .filter(contact => contact !== null);

        console.log('Processed contacts for import:', contacts);

        if (contacts.length === 0) {
            throw new Error('No valid email addresses found in the file');
        }

        onImportComplete(contacts);
        setImportSuccess(true);
        setImportedCount(contacts.length);
    } catch (error) {
        console.error('Import error:', error);
        setError(error.message);
    } finally {
        setImporting(false);
    }
  };

  const handleReset = () => {
    setFileData(null);
    setMappedFields({});
    setAvailableFields([]);
    setError(null);
    setImporting(false);
    setPreview([]);
    setImportSuccess(false);
    setImportedCount(0);
  };

  if (importSuccess) {
    return (
      <Box sx={styles.successAlert}>
        <Alert 
          severity="success" 
          sx={{ mb: 2 }}
          action={
            <IconButton
              color="inherit"
              size="small"
              onClick={handleReset}
              title="Import new contacts"
            >
              <Refresh />
            </IconButton>
          }
        >
          Successfully imported {importedCount} contacts
        </Alert>
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      {!fileData ? (
        <Paper
          {...getRootProps()}
          sx={{
            ...styles.dropZone,
            ...(isDragActive && styles.dropZoneActive)
          }}
        >
          <input {...getInputProps()} />
          <CloudUpload sx={styles.uploadIcon} />
          <Typography variant="body1" sx={{ mb: 1 }}>
            {isDragActive
              ? 'Drop the file here'
              : 'Drag and drop a file here, or click to select'}
          </Typography>
          <Typography variant="caption" color="textSecondary">
            Supported formats: .xlsx, .xls, .csv
          </Typography>
        </Paper>
      ) : (
        <Box sx={styles.mappingContainer}>
          <Typography variant="subtitle2" gutterBottom>
            Map Your Fields
          </Typography>
          
          <Grid container spacing={2} sx={{ mb: 2 }}>
            {ALL_FIELDS.map((field) => (
              <Grid item xs={12} sm={6} key={field}>
                <FormControl 
                  fullWidth 
                  error={field === 'email' && !mappedFields.email} 
                  size="small"
                >
                  <InputLabel>
                    {FIELD_LABELS[field]} {field === 'email' && '*'}
                  </InputLabel>
                  <Select
                    value={mappedFields[field] || ''}
                    onChange={(e) => handleFieldMapping(field, e.target.value)}
                    label={`${FIELD_LABELS[field]} ${field === 'email' ? '*' : ''}`}
                  >
                    <MenuItem value="">
                      <em>None</em>
                    </MenuItem>
                    {availableFields.map((header) => (
                      <MenuItem key={header} value={header}>
                        {header}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            ))}
          </Grid>

          {preview.length > 0 && (
            <Box sx={styles.previewContainer}>
              <Typography variant="caption" gutterBottom>
                Preview (First 3 rows):
              </Typography>
              <Paper sx={styles.previewPaper}>
                {preview.map((row, index) => (
                  <Box key={index} sx={styles.previewRow}>
                    {row.join(', ')}
                  </Box>
                ))}
              </Paper>
            </Box>
          )}

          {error && (
            <Alert severity="error" sx={{ mb: 2 }}>
              {error}
            </Alert>
          )}

          {importing && <LinearProgress sx={{ mb: 2 }} />}

          <Box sx={styles.buttonContainer}>
            <Button
              variant="contained"
              onClick={handleImport}
              disabled={importing || !mappedFields.email}
              startIcon={importing ? null : <Check />}
              size="small"
            >
              {importing ? 'Importing...' : 'Import Contacts'}
            </Button>
            <Button
              variant="outlined"
              onClick={handleReset}
              disabled={importing}
              size="small"
            >
              Cancel
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};

export default ContactImport; 