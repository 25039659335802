import React from "react";
import { useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  CssBaseline,
  Divider,
  Drawer,
  FormControlLabel,
  List,
  ListItem,
  Switch,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import {
  faAddressCard,
  faArrowRightFromBracket,
  faChartColumn,
  faComments,
  faDatabase,
  faDice,
  faEnvelopeOpenText,
  faFileLines,
  faGraduationCap,
  faPenToSquare,
  faUserAstronaut,
  faUsers,
  faUserTie,
} from "@fortawesome/free-solid-svg-icons";
import { DarkMode, LightMode } from "@mui/icons-material";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Sidebar.css";

const drawerWidth = 240;
const coursesURL = "https://cai3p0-internship-program-community.circle.so";

const switchStyles = {
  "& .MuiSwitch-switchBase": {
    color: "#015d86",
  },
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#015d86",
  },
  "& .MuiSwitch-switchBase + .MuiSwitch-track": {
    backgroundColor: "#015d86",
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#015d86",
  },
};

const formControlLabelStyles = {
  width: "0%",
  marginLeft: "2px",
  "& .MuiTypography-root": {
    fontSize: "12px",
    marginTop: "3px",
  },
};

const Sidebar = ({
  isDarkMode,
  isAdmin,
  onQuestionClick,
  fromFullScreenChat,
  mobileOpen,
  handleDrawerToggle,
  handleSignOut,
  toggleTheme,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down("lg"));

  const colorModeStyle = isDarkMode ? "white" : "grey";

  const buttonStyles = {
    color: "#72757C",
    textAlign: "left",
    fontWeight: "400",
    lineHeight: "130%",
    fontStyle: "normal",
    fontFamily: "Inter",
    borderRadius: "8px",
    padding: "10px 16px",
    background: "#E5E7EB",
    textTransform: "none",
    justifyContent: "flex-start",
    backgroundColor: "transparent",
    "&:hover .icon-and-text": {
      color: isDarkMode ? "white" : "black",
      fontWeight: "550",
      transform: "translate(2px, -2px)",
    },
  };

  const tooltipStyles = {
    sx: {
      bgcolor: "transparent",
      color: isDarkMode ? "#fff" : "#333",
      fontWeight: 700,
      fontSize: "14px",
      padding: "0px",
      "& .MuiTooltip-arrow": {
        color: "transparent",
      },
      "& .MuiTooltip-tooltip": {
        boxShadow: "none",
        padding: "2px 8px",
        marginLeft: "-8px",
      },
    },
  };

  const handleRedirectClick = (route) => {
    onQuestionClick(null);
    navigate(route);
    if (isMobileOrTablet) {
      handleDrawerToggle();
    }
  };

  const menuItems = [
    {
      label: "Internship Assistant",
      icon: faUserAstronaut,
      route: "/chat",
    },
    isAdmin && {
      label: "Admin Assistant",
      icon: faUserTie,
      route: "/admin-chat",
    },
    isAdmin && {
      label: "Playground",
      icon: faDice,
      route: "/playground",
    },
    isAdmin && {
      label: "Prompts",
      icon: faPenToSquare,
      route: "/prompts",
    },
    isAdmin && {
      label: "Interviews",
      icon: faComments,
      route: "/interviews",
    },
    {
      label: "Case Study",
      icon: faFileLines,
      route: "/case-study",
    },
    {
      label: "Knowledge Base",
      icon: faDatabase,
      route: "/",
    },
    isAdmin && {
      label: "Customers",
      icon: faUsers,
      route: "/customers",
    },
    isAdmin && {
      label: "Contacts",
      icon: faAddressCard,
      route: "/contacts",
    },
    isAdmin && {
      label: "Campaigns",
      icon: faEnvelopeOpenText,
      route: "/campaigns",
    },
    isAdmin && {
      label: "Metrics",
      icon: faChartColumn,
      route: "/metrics",
    },
    {
      label: "Courses",
      icon: faGraduationCap,
      route: null, // If it's an external link
      external: true,
    },
    {
      label: "Sign out",
      icon: faArrowRightFromBracket,
      action: handleSignOut,
    },
  ].filter(Boolean);

  const drawer = (
    <Box
      sx={{
        height: "100%",
        display: "flex",
        position: "relative",
        borderBottom: "none",
        flexDirection: "column",
        backgroundColor: isDarkMode ? "#1E1E1E" : "#F3F4F6",
      }}
    >
      <List
        sx={{
          flexGrow: 1,
          marginTop: "15px",
          justifyContent: isMobileOrTablet ? "flex-start" : "center",
        }}
      >
        {menuItems.map(({ label, icon, route, external, action }, index) => (
          <ListItem key={index} sx={{ justifyContent: "center" }}>
            <Tooltip
              title={
                <Box
                  sx={{
                    gap: "8px",
                    display: "flex",
                    borderRadius: "8px",
                    padding: "8px 12px",
                    alignItems: "center",
                    marginLeft: "-10px",
                    color: isDarkMode ? "#fff" : "#333",
                    boxShadow: "0px 4px 12px rgba(0, 0, 0, 0.15)",
                    backgroundColor: isDarkMode ? "#333" : "#f5f5f5",
                    border: isDarkMode ? "1px solid #555" : "1px solid #ddd",
                  }}
                >
                  <Typography
                    sx={{
                      fontSize: "14px",
                      fontWeight: 600,
                    }}
                  >
                    {label}
                  </Typography>
                </Box>
              }
              placement="right-start"
              componentsProps={{
                tooltip: tooltipStyles,
              }}
            >
              <Button
                sx={{
                  ...buttonStyles,
                  gap: "10px",
                  display: "flex",
                  padding: "10px 5px",
                  alignItems: "center",
                  justifyContent: isMobileOrTablet ? "space-between" : "center",
                }}
                fullWidth
                onClick={
                  action
                    ? action
                    : external
                    ? () => window.open(coursesURL, "_blank")
                    : () => handleRedirectClick(route)
                }
              >
                <Box
                  sx={{
                    width: "auto",
                    flexShrink: 0,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "flex-start",
                  }}
                >
                  <FontAwesomeIcon
                    className="icon-and-text"
                    icon={icon}
                    style={{
                      fontSize: "22px",
                      transition: "transform 0.3s ease, color 0.3s ease",
                    }}
                  />
                </Box>
                {isMobileOrTablet && (
                  <Box
                    sx={{
                      flexGrow: 1,
                      display: "flex",
                      justifyContent: "center",
                    }}
                  >
                    <Typography
                      className="icon-and-text"
                      sx={{
                        color: "black",
                        fontSize: "14px",
                      }}
                    >
                      {label}
                    </Typography>
                  </Box>
                )}
              </Button>
            </Tooltip>
          </ListItem>
        ))}
      </List>
      {/* <Divider
        sx={{
          backgroundColor: isDarkMode ? "white" : "#BDC3C760",
        }}
      />
      <Box
        sx={{
          padding: "10px 16px",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <FormControlLabel
          control={
            <Switch
              sx={switchStyles}
              onChange={toggleTheme}
              checked={isDarkMode}
            />
          }
          sx={formControlLabelStyles}
        />
        <Button
          sx={{ textTransform: "none", color: "#1a749c" }}
          startIcon={isDarkMode ? <DarkMode /> : <LightMode />}
        >
          {isDarkMode ? "Dark" : "Light"}
        </Button>
      </Box> */}
    </Box>
  );

  return (
    <React.Fragment>
      <Box sx={{ flexGrow: 1, width: "100%" }}>
        {<CssBaseline />}
        {isMobileOrTablet ? (
          <Drawer
            variant="temporary"
            open={mobileOpen}
            onClose={handleDrawerToggle}
            ModalProps={{
              keepMounted: true,
            }}
            sx={{
              "& .MuiDrawer-paper": {
                width: drawerWidth,
                boxSizing: "border-box",
              },
            }}
          >
            {drawer}
          </Drawer>
        ) : (
          <Drawer
            variant="permanent"
            sx={{
              display: "flex",
              flexDirection: "column",
              "& .MuiDrawer-paper": {
                width: "60px",
                boxSizing: "border-box",
                overflowX: "hidden",
                transition: "width 0.3s ease",
              },
            }}
            open
          >
            {!fromFullScreenChat && drawer}
          </Drawer>
        )}
      </Box>
    </React.Fragment>
  );
};

export default Sidebar;
