import React from "react";
import { Alert, Stack } from "@mui/material";

const InfoAlert = ({ message, textSize }) => {
  return (
    <Stack sx={{ width: "100%" }} spacing={2}>
      <Alert
        severity="info"
        variant="outlined"
        sx={{ fontSize: textSize ?? "13px", padding: "5px" }}
      >
        {message}
      </Alert>
    </Stack>
  );
};

export default InfoAlert;
