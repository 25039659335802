import React, { useMemo } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Lottie from "lottie-react";
import CheckAnimation from "../../utils/Animations/CheckAnimation.json";
import ErrorAnimation from "../../utils/Animations/ErrorAnimation.json";
import LoadingAnimation from "../../utils/Animations/LoadingAnimation.json";

const animations = {
  success: { loop: false, effect: CheckAnimation },
  error: { loop: false, effect: ErrorAnimation },
  loading: { loop: true, effect: LoadingAnimation },
};

const NotificationDialog = ({ open, onClose, title, message, type }) => {
  const animation = useMemo(() => animations[type], [type]);

  return (
    animation && (
      <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
        <Box
          sx={{
            padding: 1,
            display: "flex",
            textAlign: "center",
            alignItems: "center",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <DialogTitle
            sx={{
              mb: type === "success" ? 1 : -5,
            }}
          >
            {title}
          </DialogTitle>
          <Lottie
            loop={animation.loop}
            animationData={animation.effect}
            style={{
              height: type === "success" ? 100 : 200,
            }}
          />
          <DialogContent sx={{ mt: type === "success" ? 1 : -5 }}>
            <DialogContentText>{message}</DialogContentText>
          </DialogContent>
          {type !== "loading" && (
            <DialogActions>
              <Button onClick={onClose} color="primary">
                Close
              </Button>
            </DialogActions>
          )}
        </Box>
      </Dialog>
    )
  );
};

export default NotificationDialog;
