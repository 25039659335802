import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { COLORS } from "../utils/constants";

const SaveDeleteButtons = ({ isLoading, prompt, onCreate, onDelete }) => (
  <Box
    left={0}
    right={0}
    bottom={30}
    padding="5px"
    display="flex"
    marginBottom="20px"
    justifyContent={prompt ? "space-between" : "center"}
  >
    <Button
      onClick={onCreate}
      variant="contained"
      disabled={isLoading}
      startIcon={
        isLoading ? (
          <CircularProgress size={20} color="inherit" />
        ) : (
          <FontAwesomeIcon icon={faCheck} style={{ fontSize: "13px" }} />
        )
      }
      aria-busy={isLoading}
      sx={{
        textTransform: "none",
        backgroundColor: COLORS.PRIMARY,
        "&:hover": { backgroundColor: COLORS.PRIMARY_HOVER },
      }}
    >
      <Typography sx={{ fontSize: "13px" }}>
        {isLoading ? "Saving..." : prompt ? "Save changes" : "Create prompt"}
      </Typography>
    </Button>
    {prompt && (
      <Button
        color="error"
        variant="contained"
        onClick={onDelete}
        startIcon={
          <FontAwesomeIcon icon={faTrash} style={{ fontSize: "15px" }} />
        }
        sx={{ textTransform: "none", backgroundColor: COLORS.ERROR }}
      >
        <Typography sx={{ fontSize: "13px" }}>Delete Prompt</Typography>
      </Button>
    )}
  </Box>
);

export default SaveDeleteButtons;
