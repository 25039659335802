import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleMinus } from "@fortawesome/free-solid-svg-icons";
import {
  Box,
  IconButton,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";

const questionTypes = [
  { value: "short-answer", label: "Short answer" },
  { value: "yes-or-no", label: "Yes or no" },
  { value: "multiple-choice", label: "Multiple choice" },
  { value: "file-upload", label: "File upload" },
  { value: "date", label: "Date" },
];

const QuestionsFields = ({
  question,
  index,
  onDelete,
  disableDelete,
  error,
  onChange,
}) => {
  const [questionType, setQuestionType] = useState("short-answer");

  const handleQuestionTypeChange = (event) => {
    setQuestionType(event.target.value);
  };

  return (
    <Box
      key={index}
      sx={{
        padding: "9px",
        marginBottom: 2,
        marginTop: "15px",
        marginRight: "20px",
        borderRadius: "6px",
        position: "relative",
        border: "1px solid #ccc",
      }}
    >
      <IconButton
        sx={{
          top: "-20px",
          width: "45px",
          right: "-20px",
          color: "#D2122E",
          position: "absolute",
        }}
        onClick={onDelete}
        disabled={disableDelete}
      >
        <FontAwesomeIcon icon={faCircleMinus} />
      </IconButton>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            marginRight: "10px",
            flexDirection: "column",
          }}
        >
          <Typography variant="body2" fontWeight="bold">
            {`Question ${index + 1}`}
            {index === 0 && <span style={{ color: "red" }}>*</span>}
          </Typography>
          <TextField
            fullWidth
            value={question}
            onChange={onChange}
            variant="outlined"
            placeholder={`Question`}
            error={!!error}
            helperText={error}
            sx={{
              marginTop: "4px",
              marginBottom: "10px",
              backgroundColor: "white",
              "& .MuiInputBase-root": { height: "36px" },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": {
                  borderColor: error ? "#d32f2f" : "black",
                },
              },
            }}
            InputProps={{
              sx: { fontSize: "14px" },
            }}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="body2" fontWeight="bold">
            Question type
          </Typography>
          <TextField
            select
            fullWidth
            value={questionType}
            placeholder="Question type"
            onChange={handleQuestionTypeChange}
            sx={{
              marginTop: "4px",
              marginBottom: "10px",
              backgroundColor: "white",
              "& .MuiInputBase-root": { height: "36px" },
              "& .MuiOutlinedInput-root": {
                "&.Mui-focused fieldset": { borderColor: "black" },
              },
            }}
            InputProps={{
              sx: { fontSize: "14px" },
            }}
          >
            {questionTypes.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      </Box>
      <Typography variant="body2" fontWeight="bold">
        Expected Response
      </Typography>
      <TextField
        placeholder="Expected response"
        variant="outlined"
        fullWidth
        sx={{
          marginTop: "4px",
          marginRight: "10px",
          marginBottom: "10px",
          backgroundColor: "white",
          "& .MuiInputBase-root": { height: "36px" },
          "& .MuiOutlinedInput-root": {
            "&.Mui-focused fieldset": { borderColor: "black" },
          },
        }}
        InputProps={{
          sx: { fontSize: "14px" },
        }}
      />
    </Box>
  );
};

export default QuestionsFields;
