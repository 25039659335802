import React, { useState, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { GridLoader } from "react-spinners";
import DataTable from "../../../components/DataTable/DataTable";
import { API } from "aws-amplify";
import { getContact } from "../../../graphql/queries";
import { COLUMN_CONTACTS } from "../../Contacts/utils/constants";

const ContactsSelectionTable = ({
  onNext,
  selectedContacts,
  setSelectedContacts,
}) => {
  const [contacts, setContacts] = useState([]);
  const [contactsLoaded, setContactsLoaded] = useState(false);

  useEffect(() => {
    if (!contactsLoaded) {
      getContactsList();
    }
  }, [contactsLoaded]);

  useEffect(() => {
    console.log("ContactsSelectionTable - Props received:", {
      selectedContacts,
      hasSetSelectedContacts: !!setSelectedContacts,
    });
  }, [selectedContacts]);

  useEffect(() => {
    console.log("DataTable props:", {
      type: "contacts",
      dataLength: contacts.length,
      selectedRowsLength: selectedContacts?.length,
      hasOnSelect: !!handleContactSelect,
      hasMultiSelect: true,
      hasCheckboxes: true,
    });
  }, [contacts, selectedContacts]);

  const getContactsList = async () => {
    try {
      const params = { id: "", customer: "cai3p0" };
      const response = await API.graphql({
        query: getContact,
        variables: params,
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      const rawContacts = JSON.parse(response.data.getContact.body);

      const fetchedContacts = rawContacts.map((contact) => ({
        ...contact,
        id: contact.id || String(Math.random()),
        name:
          contact.name ||
          `${contact.firstname || ""} ${contact.lastname || ""}`.trim(),
        email: contact.email || "",
        phone: contact.phone || "",
      }));

      console.log("Contacts fetched successfully:", {
        total: fetchedContacts.length,
        sample: fetchedContacts[0],
      });

      setContacts(fetchedContacts);
      setContactsLoaded(true);
    } catch (error) {
      console.error("Error fetching contacts:", error);
    }
  };

  const handleContactSelect = (contact) => {
    console.log("Selection attempt:", {
      contact,
      currentSelectedContacts: selectedContacts,
      setSelectedContactsExists: !!setSelectedContacts,
    });

    if (Array.isArray(contact)) {
      // Handle select all case
      const validContacts = contact.filter(c => c?.email);
      setSelectedContacts(validContacts);
      return;
    }

    if (!contact?.email) {
      console.warn("Contact missing email:", contact);
      return;
    }

    setSelectedContacts((prev) => {
      const currentSelection = Array.isArray(prev) ? prev : [];
      const isSelected = currentSelection.some((c) => c.id === contact.id);

      const newSelection = isSelected
        ? currentSelection.filter((c) => c.id !== contact.id)
        : [...currentSelection, contact];
      
      console.log('Selection updated:', {
        wasSelected: isSelected,
        newSelectionCount: newSelection.length,
        newSelection: newSelection
      });

      return newSelection;
    });
  };

  return (
    <Box sx={{ p: 2, height: "100%" }}>
      {contactsLoaded ? (
        <>
          <Typography variant="subtitle1" sx={{ mb: 2 }}>
            Selected contacts: <strong>{selectedContacts?.length || 0}</strong>
          </Typography>
          <DataTable
            type="contacts"
            data={contacts}
            columns={COLUMN_CONTACTS}
            onSelect={handleContactSelect}
            selectedRows={selectedContacts || []}
            selectable={true}
            showCheckboxes={true}
            multiSelect={true}
          />
          <Box sx={{ mt: 2, display: "flex", justifyContent: "flex-end" }}>
            <Button
              variant="contained"
              onClick={() => {
                console.log(
                  "Next button clicked with contacts:",
                  selectedContacts
                );
                onNext();
              }}
              disabled={!selectedContacts || selectedContacts.length === 0}
              sx={{
                backgroundColor: "#015d86",
                "&:hover": { backgroundColor: "#014a6d" },
                "&:disabled": {
                  backgroundColor: "#cccccc",
                  cursor: "not-allowed",
                },
                textTransform: "none",
                fontSize: "13px",
              }}
            >
              Next
            </Button>
          </Box>
        </>
      ) : (
        <Box sx={{ display: "flex", justifyContent: "center", mt: 4 }}>
          <GridLoader />
        </Box>
      )}
    </Box>
  );
};

export default ContactsSelectionTable;